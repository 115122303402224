import React from 'react';
import './About.css';

const About = () => (
  <section id="about">
    <h2>About Us</h2>
    <p>We provide the best services for planning your perfect event, whether it's a wedding, conference, or party.</p>
  </section>
);

export default About;
